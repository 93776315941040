import React from 'react';

const Button = (props) => {
  const { btnName, btnStyle, type,disabled ,onClick} = props;

  return (
    <button
      style={{ boxShadow: '#050E210A 0px 4px 8px' }}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${btnStyle} w-fit font-medium`}
    >
      {btnName}
    </button>
  );
};

export default Button;

