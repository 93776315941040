import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showThanksPopup: false,
    // other states
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        ThanksPopupView: (state, action) => {
            state.showThanksPopup = action.payload; // accepts true or false
        },
    },
});

export const { ThanksPopupView } = uiSlice.actions;
export default uiSlice.reducer;
