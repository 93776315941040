import React from "react";
import RolesImage from "../../assets/images/workflow.svg";

const RolesAssignment = () => {
  return (
    <div className="w-[100%]">
      <div className=" container gap-10 flex md:flex-row flex-col-reverse items-center justify-between  md:py-24 py-8">
        <div className="md:w-[50%] w-[90%]">
          <img src={RolesImage} alt="RolesImage" />
        </div>
        <div className="md:w-[40%] w-[100%] p-[20px]">
          <p className="mb-[24px] leading-[55px] font-semibold md:text-[40px] text-[24px]">
            Prospect Workflow
          </p>
          <p className="m-0 md:text-[20px] text-[16px]">
            Only valid contacts move to the Prospects module, with reassignment
            options for managers, ensuring no opportunities are missed as
            prospects advance based on activity and status.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RolesAssignment;
