const config = {

    production: {
        url: "https://www.leadsynq.com",
        apiUrl: "https://45qcttm7nk.execute-api.ap-south-1.amazonaws.com/prod/",
        region: "ap-south-1",
        bucketName: "leadsynq-prod-super-admin-app-01",
        s3_url: "https://leadsynq-prod-super-admin-app-01.s3.ap-south-1.amazonaws.com/",
    },

    develop: {
        url: "",
        apiUrl: "https://s72r6hav0g.execute-api.ap-south-1.amazonaws.com/dev/",
        region: "ap-south-1",
        bucketName: "leadsynq-staging-super-admin-app-01",
        s3_url: "https://leadsynq-staging-super-admin-app-01.s3.ap-south-1.amazonaws.com/",
    },
    local: {
        url: "http://localhost:5000/",
        apiUrl: "http://localhost:8081/dev/",
    },
};

export const environment = "production";

const hostConfig = {
    WEB_URL: config[environment].url,
    API_URL: config[environment].apiUrl,
    S3_BUCKET: `${config[environment].bucketName}`,
    IMAGE_URL: config[environment].s3_url,
};

export { hostConfig };