import React from "react";
import DashboardImage from "../../assets/images/contact-hub.svg";

const KPIDashboard = () => {
  return (
    <div className="w-[100%]">
      <div className=" container gap-10 flex md:flex-row flex-col items-center justify-between  md:py-24 py-8">
        <div className="md:w-[40%] w-[100%] p-[20px]">
          <p className="mb-[24px] leading-[55px] font-semibold md:text-[40px] text-[24px]">
          Contact Hub
          </p>
          <p className="m-0 md:text-[20px] text-[16px]">
            {
              "Manage contacts through tabs like New, Assigned, and Deals; easily import data in CSV/XLSX formats, categorize, and assign for smooth progression through the sales funnel."
            }
          </p>
        </div>
        <div className="md:w-[50%] w-[90%] flex justify-end">
        <img src={DashboardImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default KPIDashboard;
