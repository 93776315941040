import React from 'react'
import PartnerBannerImage from '../../assets/images/partnerBannerImage.png'

const PartnerBanner = () => {
    return (
        <div className='bg-[#F2F8FF] container py-[20px] md:mt-[80px] flex-col md:flex-row justify-center flex md:gap-[50px] items-center'>

            <div className='md:w-[45%] w-[100%] md:p-[60px]'>
                <p className='m-0 text-[#062B70] text-[14px] font-semibold'>JOIN OUR PARTNER PROGRAM</p>
                <p className='mt-[10px] mb-[20px]  text-[#050E21] leading-[50px] font-bold text-[40px]'>Collaborate with us
                    <br /> as a Partner</p>
                <p className=' text-[14px] text-[#354667] '>Join us as a Keep Certified Partner to access exclusive resources, training, and support for delivering exceptional CRM solutions to clients. Together, we'll drive success for small businesses globally.</p>
            </div>
            <div className='md:w-[45%] w-[90%]'>
                <img className=' w-[100%] md:w-[80%]' src={PartnerBannerImage} alt="banner_image" />
            </div>
        </div>
    )
}

export default PartnerBanner
