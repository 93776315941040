import React from "react";
import PerformanceImage from "../../assets/images/performance.svg";

const Performance = () => {
  return (
    <div className="w-[100%]">
      <div className=" container gap-10 flex md:flex-row flex-col items-center justify-between  md:py-24 py-8">
        <div className="md:w-[40%] w-[100%] p-[20px]">
          <p className="mb-[24px] leading-[55px] font-semibold md:text-[40px] text-[24px]">
            Comparison (Performance)
          </p>
          <p className="m-0 md:text-[20px] text-[16px]">
            {
              "Benchmark performance, analyze trends, and compare metrics visually to gain actionable insights."
            }
          </p>
        </div>
        <div className="md:w-[50%] w-[90%]">
          <img src={PerformanceImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Performance;
