import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{ textAlign: "center", height: "60vh" }}
    >
      <div>
        <h1>404 - Page Not Found</h1>
        <p className="my-8">
          Sorry, we couldn’t find that page. But rest assured, LeadsynQ keeps
          all your <br/> leads close at hand!
        </p>
        <Link to="/">Go Back Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
