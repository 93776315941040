import React from "react";
import TrackingImage from "../../assets/images/pipeline.svg";
const TrackingUpdates = () => {
  return (
    <div className="w-[100%]">
      <div className="bg-[#FB761C14] ">
        <div className="flex md:flex-row flex-col-reverse items-center justify-between md:py-24 py-8 container">
          <div className="md:w-[50%] w-[100%]">
            <img src={TrackingImage} alt="import_img" />
          </div>

          <div className="flex flex-col w-[90%] md:w-[40%] md:pb-0 pb-5">
            <p className="mb-[24px] md:text-[40px] text-[24px] md:pt-0 pt-5 font-semibold">
              {"Sales Pipeline Monitoring"}
            </p>
            <p className="m-0 md:text-[20px] text-[16px] text-[#354667]">
              {
                "Track deals through stages like Proposal Sent, Won, or Lost, with successful deals automatically transitioning to the Client module for ongoing management."
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingUpdates;
