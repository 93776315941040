import React from "react";
import KanbanImage from "../../assets/images/dashboard.svg";

const KanbanBoard = () => {
  return (
    <div className="w-[100%]">
      <div className=" container gap-10 flex md:flex-row flex-col-reverse items-center justify-between  md:py-24 py-8">
        <div className="md:w-[50%] w-[90%]">
          <img src={KanbanImage} alt="RolesImage" />
        </div>
        <div className="md:w-[40%] w-[100%] p-[20px]">
          <div>
            <p className="mb-[24px] leading-[55px] font-semibold md:text-[40px] text-[20px]">
            Live Dashboard Panel
            </p>
            <p className="m-0 md:text-[20px] text-[14px]">
              {
                "Tailored role-based dashboards for admins, managers, and executives provide detailed metrics and visualizations, tracking contacts, leads, and deals through intuitive charts and graphs."
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanbanBoard;
