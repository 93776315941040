import React, { useState, useEffect } from "react";
import cookies from "react-cookies";
import { v4 as uuidv4 } from 'uuid'; // Import UUID function

// import "./cookies.css"; // Update the import to use normal CSS instead of module.css
import styles from "./cookies.module.css";

import CookieImage from "../../assets/surface-cookies.svg"; // Update the path for your image
import CookieUnion from "../../assets/Union.svg"; // Update the path for your image


const Cookies = () => {
  const [consent, setConsent] = useState(true); // Default consent to false
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Check if 'consent_id' cookie exists
    const consentId = cookies.load("consent_id"); // Unified the cookie name


    if (!consentId) {
      setConsent(false); // If the cookie is found, set consent to true
    }
    if (consentId) {
      setConsent(true); // If the cookie is found, set consent to true
      setUserId(consentId); // Set the userId from the cookie
    }
  }, []);

  const acceptCookie = () => {
    // Set consent and generate a unique user ID
    const storedId = uuidv4(); // Generate a new unique ID
    cookies.save("consent_id", storedId, { path: "/", maxAge: 60 * 60 * 24 * 365 }); // Save cookie for 1 year
    setConsent(true); // Set consent to true
    setUserId(storedId); // Set the userId to the generated ID
  };

  // If consent is already given, don't render the cookie popup
  if (consent) return null;

  return (
    <>
      <div className={` ${consent ? "hidden" : ""} ${styles.CookiePop}  mx-auto`}>
        <div className="flex justify-between items-center  p-6 sm:p-8 max-lg:block">
          <div className="flex items-center">
            {" "}
            <img
              src={CookieImage}
              alt="Logo"
              style={{ width: "90px" }}
            />
            <p className="pr-[70px] px-8 text-[14px] sm:text-base  max-lg:pr-0">
              Our website use cookies. By continuing navigating, we assume your
              <br />
              permission to deploy cookies as detailed in our Privacy Policy.
            </p>
          </div>
          <div className="flex justify-center items-center pr-8 max-xl:pr-0 max-lg:pt-8 max-lg:justify-start">
            <button
              style={{ border: "1px solid #FFFFFF26" }}
              onClick={() => {
                acceptCookie();
              }}
              className="text-[#ffffff] bg-[#141A28] hover:bg-[#0046BF] rounded-full  px-[24px] py-[12px] mr-[20px] text-base font-medium leading-[100%]"
            >
              Decline
            </button>
            <button
              className="text-[#ffffff] bg-[#141A28] hover:bg-[#0046BF] rounded-full  px-[24px] py-[12px] mr-[20px] text-base font-medium leading-[100%]"
              style={{ border: "1px solid #FFFFFF26" }}
              onClick={() => {
                acceptCookie();
              }}
            >
              {" "}
              <div className="flex items-center">
                <span className=" mr-3">
                  <img src={CookieUnion} alt="Right-arrow" />
                </span>
                Accept cookies
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
