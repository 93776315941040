import { combineReducers, configureStore } from "@reduxjs/toolkit";
import postAffiliateSlice from "../slices/post-affiliate.slice";
import postContactSlice from "../slices/post-contact.slice";
import getSubscriptionsSlice from "../slices/get-subscription.slice";
import uiSlice from "../slices/bookings-popup.slice";


const reducers = combineReducers({
    postAffiliate: postAffiliateSlice,
    postContact: postContactSlice,
    getSubscriptions: getSubscriptionsSlice,
    uiSlice: uiSlice
})



const store = configureStore({
    reducer: reducers,
    middleware: ((getDefaultMiddleware) => getDefaultMiddleware()),
});
export default store