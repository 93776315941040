import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import Image1 from "../../assets/images/image1.png";
import { fetchGetSubscriptions } from "../../redux/slices/get-subscription.slice";
import { useDispatch } from "react-redux";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import ThanksPopup from "../../components/thank-you-popup";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoClose } from "react-icons/io5";
import { postBookings } from "../../api/create";
import { useLocation, useNavigate } from "react-router-dom";
const BannerSection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showThanksPopup, setShowThanksPopup] = useState(false);

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // const parameterValue = searchParams.get("demo-booking");
    if (location.search.includes("free-demo")) {
      setShowThanksPopup(true);
    }
  }, [location]);

  const getEventData = async (value) => {
    console.log(value, "resource5555");
    
    const token =
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzMwMjA0OTkzLCJqdGkiOiIzMWU1ZmY3My1iNjlmLTRmNDktOGFmNC02ZjNiNTc5N2ViN2EiLCJ1c2VyX3V1aWQiOiI0M2VlNTJkYi0zYTk1LTQ3MzItYjZlYi02ZTc5YmZiY2ZkZWIifQ._RXoYow8tAPShRH2sFB_mcclm1cGKCf23K_sMF4Ib4tWsY489ic530oX0g3d_KJuKukCki3imjAUpQyqDQZB7w";
    try {
      const response = await fetch(`${value?.payload?.invitee?.uri}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const response2 = await fetch(`${value?.payload?.event?.uri}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      if (!response2.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const data2 = await response2.json();
      const payload = {
        invitee: data,
        event: data2,
      };
      await postBookings(payload).then((res) => {
        console.log(res, "booking res--->>>>");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const data = useCalendlyEventListener({
    onDateAndTimeSelected: (e) => {
      console.log(e, "fsdf2222");
    },
    onEventScheduled: async (e) => {
      await getEventData(e.data);
    },
    onEventTypeViewed: (e) => {
      console.log(e, "6666666");
    },
    onProfilePageViewed: (e) => {
      console.log(e, "55555555");
    },
  });

  console.log(data, "data4444444");

  useEffect(() => {
    dispatch(fetchGetSubscriptions());
  }, [dispatch]);

  const openCalendly = () => {
    setShowThanksPopup(true);
  };

  return (
    <div className="absolute md:w-[98.5vw] z-10">
      <div className="container md:h-[95vh] md:flex-row w-[100%] flex-col flex items-center justify-center">
        <div className="md:w-[50%] 2-[100%] flex flex-col gap-[24px]">
          <p className="m-0 font-bold md:text-[40px] text-[30px]">
            Need Top Lead Management to Prevent Lost Conversions?
          </p>

          <p className="m-0 md:w-[75%] w-[100%] font-normal text-[16px] leading-6 text-[#000000]">
            {
              "Seamlessly capture, nurture, and track every lead in one place, then hand it off to the perfect team member. Simplify your process and watch your sales cycle shrink today!"
            }{" "}
          </p>
          <div onClick={openCalendly}>
            <Button
              btnName="Get free demo"
              btnStyle="cursor-pointer bg-[#0F337C] py-[12px] px-[16px] rounded-[8px] text-[14px] text-white"
            />
          </div>
        </div>
        <div className="md:w-[50%] w-full h-[100%] flex items-center">
          <img width={"100%"} src={Image1} alt="..." />
        </div>
      </div>

      <div className=""></div>
      <ThanksPopup
        showThanksPopup={showThanksPopup}
        setShowThanksPopup={setShowThanksPopup}
      >
        <div>
          <div className="w-[100%]  flex justify-end ">
            <IoClose
              size={30}
              className="cursor-pointer mb-[20px]"
              onClick={() => {setShowThanksPopup(false); navigate("/");}}
            />
          </div>
          <InlineWidget url="https://calendly.com/hello-leadsynq" />
        </div>
      </ThanksPopup>
      <div></div>
    </div>
  );
};

export default BannerSection;
