import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSubscriptions } from "../../api/list";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}


export const fetchGetSubscriptions = createAsyncThunk(
    "getSubscriptions",
    async (params, { dispatch }) => {
        const response = await getSubscriptions(params);

        if (response) {
            dispatch(addGetSubscriptions(response));
        }

        return response

    }
)


const getSubscriptionsSlice = createSlice({
    name: "getSubscriptions",
    initialState: initialState,
    reducers: {
        addGetSubscriptions: (state, action) => {
            state.storeResponseData = action.payload
        }
    }

});


export default getSubscriptionsSlice.reducer

export const { addGetSubscriptions } = getSubscriptionsSlice.actions