import React from "react";
import Icon1 from "../../assets/icons/Icon1.png";
import Icon2 from "../../assets/icons/Icon2.png";
import Icon3 from "../../assets/icons/Icon3.png";
import Icon4 from "../../assets/icons/Icon4.png";
import Icon5 from "../../assets/icons/Icon5.png";
import Icon6 from "../../assets/icons/Icon6.png";
import Card from "../../components/card";
import SolutionImage from "../../assets/images/solutionImage.png";

const TrackingSolution = () => {
  const data = [
    {
      icon: Icon1,
      caption: "Lead Optimization",
      description:
        "Unify and streamline lead sources, maximizing efficiency and preventing gaps.",
    },
    {
      icon: Icon2,
      caption: "Quick Alerts",
      description:
        "Stay on track with instant alerts for leads, follow-ups, and deadlines.",
    },
    {
      icon: Icon3,
      caption: "Advanced Analytics",
      description:
        "Real-time analytics and custom reports for smarter decisions and better sales.",
    },
    {
      icon: Icon4,
      caption: "Cold Reach Management",
      description:
        "Engage potential leads effectively with our structured outreach tracking system.",
    },
    {
      icon: Icon5,
      caption: "Lead Tracking Console",
      description:
        "Monitor, manage, and optimize all your leads from one central console.",
    },
    {
      icon: Icon6,
      caption: "Smooth Team Dynamics",
      description:
        "Enhance collaboration, communication, and efficiency for seamless team performance.",
    },
  ];

  return (
    <div className="relative ">
      <div id="solution" className=" container  mt-[20px] md:mt-[10px]">
        <div className="flex items-center flex-col justify-center">
          <p className="mb-[16px] text-[#FB761C] md:text-[40px] text-[30px] font-semibold">
            {"All-in-One Lead Management Platform"}
          </p>
          {/* <p className='m-0 text-[22px] font-medium'> Experience a seamless lead management journey with our all-in-one solution:</p> */}
        </div>

        <div className="grid  z-[100] md:grid-cols-3 gap-4 grid-cols-1 my-[57px] ">
          {data.map((item, index) => (
              <Card
                key={index}
                icon={item?.icon}
                // title={item?.title}
                caption={item?.caption}
                description={item?.description}
              />
          ))}
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${SolutionImage})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="absolute w-[100%] md:w-[50%] h-[100vh] z-[-1] top-0"
      ></div>
    </div>
  );
};

export default TrackingSolution;
