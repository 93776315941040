import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";

function PhoneNumber(props) {
  const {
    value,
    onChange,
    name,
    placeholder,
    disabled,
    className,
    errorInput,
    onBlur,
    height  
  } = props;

  console.log(errorInput, "errorInput")

  return (
    <div className={"react_phone_number form-input mt-1"}>
      <PhoneInput
       inputProps={{
        name: name,
        required: true,
        autoFocus: false,
      }}
        onBlur={onBlur}
        country={"in"}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        inputClass={`${className} ${errorInput ? "error-input" : ""}`}
        inputStyle={{ border: errorInput ? "1px solid red" : "", height: height ? height : "40px" }}
      />
    </div>
  );
}

export default PhoneNumber;
