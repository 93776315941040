import React from "react";
// import Link from "next/link";
import { hostConfig } from "../../config";
import { BsArrowRight } from "react-icons/bs";
import { MdArrowBack, MdDateRange } from "react-icons/md";
import { formattedDate } from "../../utils/date-format";
import { useNavigate } from "react-router-dom";
// import { useRouter } from "next/router";

function SearchBlogs(props) {
  const {
    data,
    groupedData,
    handleSeeMore,
    handleSeeLess,
    itemsToShow,
    filterValue,
    setFilterValue,
    fetchData,
  } = props;
  // const router = useRouter();
  const regex = /(<([^>]+)>)/gi;
  const regexAmp = /&amp;/g;
  const regexnsbp = /&nbsp;|&nsbp;/g;
  const regexGreater = /&lt;/g;
  const regexSmaller = /&gt;/g;

  const navigate = useNavigate();

  const BlogDetailsRoute = (value) => {
    navigate(value);
  };

  return (
    <div>
      <div
        className={`row justify-content-center card-contain 
          // router?.query?.category ? "pt-2" : " pt-5"
        `}
      >
        {/* Search Categories listing */}
        {data?.length !== 0 ? (
          <div>
            {Object.keys(groupedData).map((category) => (
              <div key={category}>
                <div className="popular-blogs-heading my-3 text-capitalize">
                  {category}
                </div>
                <div className="blog-categories-listing">
                  <div className="row mb-5 ">
                    {groupedData[category]
                      ?.slice(0, itemsToShow[category])
                      ?.map((item, index) => (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 mb-3"
                          key={index}
                          onClick={() => {
                            BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                          }}
                        >
                          <div className="cursor-pointer">
                            <img
                              src={`${hostConfig.IMAGE_URL}blog/${item?.filename}.png`}
                              alt="img"
                              className="img-fluid"
                              style={{
                                width: "100%",
                                height: "278px",
                              }}
                            />
                            <div className="d-flex justify-content-between">
                              <div className="pt-2 pb-2 blog-category text-capitalize">
                                {item?.category.length > 20
                                  ? item?.category.slice(0, 20) + "..."
                                  : item?.category}
                              </div>
                              <div className="d-flex align-items-center pb-2 pt-2">
                                <MdDateRange className="align-middle date" />
                                <div className="align-middle date ps-2 font-semibold">
                                  {formattedDate(item?.createdAt)}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ height: "auto" }}
                              className="mb-0 pt-1 blog-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
                            >
                              {item?.title}{" "}
                            </div>
                            <div className="popular-blog-description">
                              {" "}
                              {item?.summary
                                ? item?.summary
                                    .replace(regex, "")
                                    .replace(regexAmp, "&")
                                    ?.replace(regexnsbp, " ")
                                    .replace(regexGreater, "<")
                                    .replace(regexSmaller, ">")
                                : item?.summary
                                    .replace(regex, "")
                                    .replace(regexAmp, "&")
                                    ?.replace(regexnsbp, " ")
                                    .replace(regexGreater, "<")
                                    .replace(regexSmaller, ">")}
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  color: "#0B1D1299",
                                }}
                                className="text-capitalize"
                              >
                                {item?.authorName}
                              </div>
                              <div>
                                <div className="readmore cursor ">
                                  Read more
                                  <BsArrowRight className="fs-4 ms-2" />
                                  <div className="VectorImage">
                                    <img
                                      src="/assets/VectorImage.svg"
                                      alt="img"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {groupedData[category].length > itemsToShow[category] ? (
                      <div className="col-12 text-center">
                        <div
                          onClick={() => handleSeeMore(category)}
                          className="categories-item"
                        >
                          See More
                        </div>
                      </div>
                    ) : groupedData[category].length > 6 ? (
                      <div className="col-12 text-center">
                        <div
                          onClick={() => handleSeeLess(category)}
                          className="categories-item"
                        >
                          See Less
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
            {filterValue.map((category) => {
              const categoryData = groupedData[category];
              if (!categoryData || categoryData.length === 0) {
                return (
                  <div key={category}>
                    <div>
                      <div className="popular-blogs-heading my-3 text-capitalize">
                        {category}
                      </div>
                      <div className="mt-10">
                        <div className="d-flex justify-content-center">
                          <img
                            src="/assets/no-records.png"
                            alt="no records"
                            className="img-fluid "
                            width={250}
                            height={250}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <span className="fs-5 font-semibold record text-capitalize">
                            No{" "}
                            <span className="text-[#0F337C] text-capitalize"> {category}</span>{" "}
                            Blogs Available
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div>
            <div
              className="backToBlog pb-2"
              onClick={() => {
                setFilterValue([]);
                fetchData();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center"
              >
                <div className="font-semibold align-middle">
                  {" "}
                  <MdArrowBack className="fs-4" />
                </div>
                <div
                  onClick={() => {
                    BlogDetailsRoute(`/blogs`);
                  }}
                  className="font-semibold align-middle mx-2"
                >
                  Back to Blogs
                </div>
              </div>
            </div>
            {filterValue.map((category) => {
              const categoryData = groupedData[category];
              if (!categoryData || categoryData.length === 0) {
                return (
                  <div key={category}>
                    <div>
                      <div className="popular-blogs-heading my-3 text-capitalize">
                        {category}
                      </div>
                      <div className="mt-10">
                        <div className="d-flex justify-content-center">
                          <img
                            src="/assets/no-records.png"
                            alt="no records"
                            className="img-fluid "
                            width={250}
                            height={250}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <span className="fs-5 font-semibold record text-capitalize">
                            No{" "}
                            <span className="text-[#0F337C] text-capitalize">{category}</span>{" "}
                            Blogs Available
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBlogs;
