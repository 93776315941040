import React from "react";
import MailImage from "../../assets/images/sales.svg";

const MailRecording = () => {
  return (
    <div className="w-[100%]">
      <div className=" container gap-10 flex md:flex-row flex-col items-center justify-between  md:py-24 py-8">
        <div className="md:w-[40%] w-[100%] p-[20px]">
          <p className="mb-[24px] leading-[55px] font-semibold md:text-[40px] text-[24px]">
            Actionable Sales Insights
          </p>
          <p className="m-0 md:text-[20px] text-[16px]">
            {
              "Gain insights into lead sources, conversion rates, and scoring effectiveness while generating customized reports on lead activity, sales performance, and other key metrics."
            }
          </p>
        </div>
        <div className="md:w-[50%] w-[90%]">
          <img src={MailImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MailRecording;
