import React from "react";
// import Link from "next/link";
import { hostConfig } from "../../config";
import { BsArrowRight } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function PopularBlogs(props) {
  const { PopularData } = props;
  const regex = /(<([^>]+)>)/gi;
  const regexAmp = /&amp;/g;
  const regexnsbp = /&nbsp;|&nsbp;/g;
  const regexGreater = /&lt;/g;
  const regexSmaller = /&gt;/g;
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formatCreatedAt = new Date(
    PopularData?.[0]?.createdAt
  ).toLocaleDateString("en-GB", options);

  // const navigate = useNavigation();
  const navigate = useNavigate();

  const BlogDetailsRoute = (value) => {
    navigate(value);
  };

  return (
    <section className="popular-blogs mb-4">
      <div className="mt-5">
        <div className="popular-blogs-heading">Popular blogs</div>
        <div className="row justify-content-center mt-3">
          {/* Popular blog 1 */}
          {/* <a href={`blogs/${PopularData?.[0]?.permaLink}`}> */}
          <div
            onClick={() => {
              BlogDetailsRoute(`/blogs/${PopularData?.[0]?.permaLink}`);
            }}
            className="col-lg-7 col-md-12 cursor-pointer"
          >
            <div>
              <img
                src={`${hostConfig.IMAGE_URL}blog/${PopularData?.[0]?.filename}.png`}
                alt="blog-popular image"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "278px",
                }}
              />
              <div className="d-flex justify-content-between">
                <div className="pt-2 pb-2 blog-category text-capitalize">
                  {PopularData?.[0]?.category.length > 20
                    ? PopularData?.[0]?.category?.slice(0, 20) + "..."
                    : PopularData?.[0]?.category}
                </div>
                <div className="d-flex align-items-center pb-2 pt-2">
                  <MdDateRange className="align-middle" />
                  <div className="align-middle date ps-2 fw-semibold">
                    {formatCreatedAt}
                  </div>
                </div>
              </div>
              <div
                style={{ height: "auto" }}
                className="mb-0 pt-1 blog-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
              >
                {PopularData?.[0]?.title}{" "}
              </div>
              <div className="popular-blog-description">
                {" "}
                {PopularData?.[0]?.summary
                  ? PopularData?.[0]?.summary
                      .replace(regex, "")
                      .replace(regexAmp, "&")
                      ?.replace(regexnsbp, " ")
                      .replace(regexGreater, "<")
                      .replace(regexSmaller, ">")
                  : PopularData?.[0]?.summary
                      .replace(regex, "")
                      .replace(regexAmp, "&")
                      ?.replace(regexnsbp, " ")
                      .replace(regexGreater, "<")
                      .replace(regexSmaller, ">")}
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#0B1D1299",
                  }}
                  className="text-capitalize"
                >
                  {PopularData?.[0]?.authorName}
                </div>
                <div>
                  <div className="readmore cursor ">
                    Read more
                    <BsArrowRight className="fs-4 ms-2" />
                    <div className="VectorImage">
                      <img src="/assets/VectorImage.svg" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </a> */}
          {/* Popular blog 2 to 4 */}
          <div className="col-lg-5 col-md-12 popular-responsive ps-4">
            {PopularData?.slice(1, 4)?.map((item, index) => {
              return (
                <div className="blogs-container pb-4">
                  {/* <a href={`blogs/${item.permaLink}`}> */}
                  <div
                    onClick={() => {
                      BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                    }}
                    className="row"
                  >
                    <div className="col-5 cursor-pointer">
                      <img
                        src={`${hostConfig.IMAGE_URL}blog/${item?.filename}.png`}
                        alt="blog-popular image"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "127px",
                        }}
                      />
                    </div>
                    <div className="col-7 d-flex align-items-center cursor-pointer">
                      <div className="ps-2">
                        <div
                          style={{ height: "auto" }}
                          className="mb-0 pt-1 blog-title feature-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
                        >
                          {item?.title}
                        </div>
                        <div className="popular-blog-description">
                          {" "}
                          {item?.summary
                            ? item?.summary
                                .replace(regex, "")
                                .replace(regexAmp, "&")
                                ?.replace(regexnsbp, " ")
                                .replace(regexGreater, "<")
                                .replace(regexSmaller, ">")
                            : item?.summary
                                .replace(regex, "")
                                .replace(regexAmp, "&")
                                ?.replace(regexnsbp, " ")
                                .replace(regexGreater, "<")
                                .replace(regexSmaller, ">")}
                        </div>

                        <div className="readmore cursor mt-2">
                          Read more
                          <BsArrowRight className="fs-4 ms-2" />
                          <div className="VectorImage">
                            <img src="/assets/VectorImage.svg" alt="img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularBlogs;
