import React from "react";
import { compareDate } from "../../constant/compare-features";
import EnableCheck from "../../assets/icons/check-mark-enable.svg";
import DisableCheck from "../../assets/icons/check-mark-disable.svg";

function CompareSection() {
  return (
    <div className="container  mt-[80px]" id="pricing">
      <div className="text-center">
        <p className="m-0 md:text-[40px] text-[30px] font-bold">
          Compare{" "}
          <span
            style={{
              backgroundImage:
                "linear-gradient(266.01deg, #55CBFB -21.73%, #1D4ED8 96.22%)",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {"features"}
          </span>
        </p>
        <p className="my-[20px] text-[#888888]">
          {"Check Out the Features: Find Your Perfect Plan!"}
        </p>
      </div>
      <div className="flex items-center justify-center pt-3.5">
        <table className="w-full">
          <thead className="bg-[#EBF4FE] h-[50px] ">
            <tr>
              <th
                className="text-[#778399] font-normal text-[14px] sm:text-[18px] pl-5 sm:pl-[50px]"
                style={{ borderTopLeftRadius: "8px" }}
              >
                Features
              </th>
              <th className="text-center text-[#0F337C] text-[14px] sm:text-xl font-semibold">
                <span className="sm:hidden block">Basic </span>{" "}
                <span className="sm:block hidden">Basic plan</span>
              </th>
              <th className="text-center text-[#0F337C] text-[14px] sm:text-xl font-semibold">
                <span className="sm:hidden block">Pro </span>{" "}
                <span className="sm:block hidden">Pro plan</span>
              </th>
              <th
                style={{ borderTopRightRadius: "8px" }}
                className="text-center text-[#0F337C] text-[14px] sm:text-xl font-semibold pl-0 sm:pl-[50px]"
              >
                <span className="sm:hidden block">Custom </span>{" "}
                <span className="sm:block hidden">Custom plan</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {compareDate?.map((item, index) => {
              return (
                <tr key={index} className="border-b h-[72px] border-[#E2E3E5]">
                  <td className="w-[25%] pl-5 sm:pl-[50px] text-[#0F337C] font-semibold text-[15px] sm:text-[22px]">
                    {item?.name}
                  </td>
                  <td className="text-center w-[25%] ">
                    {item?.basicPlan === true ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={EnableCheck}
                          alt="✔️"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <img
                          src={DisableCheck}
                          alt="❌"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    )}
                  </td>
                  <td className="text-center w-[25%]">
                    {item?.proPlan === true ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={EnableCheck}
                          alt="✔️"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <img
                          src={DisableCheck}
                          alt="❌"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    )}
                  </td>
                  <td className="text-center w-[25%] pl-0 sm:pl-[50px]">
                    {item?.customPlan === true ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={EnableCheck}
                          alt="✔️"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <img
                          src={DisableCheck}
                          alt="❌"
                          className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px]"
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CompareSection;
