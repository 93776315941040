import React from "react";
import ReasonImage from "../../assets/images/reasonImage.png";
import TickIcon from "../../assets/icons/tickIcon.png";

const Reasons = () => {
  return (
    <div className="mt-0 md:mt-[100px]">
      <div className="w-[100%] bg-[#047AF814] z-10 py-[50px]">
        <div className="flex md:flex-row flex-col md:gap-0 gap-[20px] items-center container">
          <div className="flex flex-col md:p-[20px] w-[100%] md:w-[50%]">
            <p className="mb-[50px] text-[30px] font-semibold">
              Why Does <span className="text-[#0F337C]">LeadSynQ</span> Stand
              Out?{" "}
            </p>

            <div className="flex flex-col gap-[30px]">
              <div className="flex items-start gap-2">
                <img width={20} src={TickIcon} alt="..." />
                <div>
                  <p className="mb-[8px]  font-semibold">Seamless Lead Flow</p>
                  <p className="m-0 text-[#354667] text-[14px]">
                    {
                      "LeadsynQ centralizes lead management, making it easy to capture, nurture, and convert leads while ensuring no opportunities are missed."
                    }
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-2">
                <img width={20} src={TickIcon} alt="..." />
                <div>
                  <p className="mb-[8px]  font-semibold">
                    Efficient Task Management
                  </p>
                  <p className="m-0 text-[#354667] text-[14px]">
                    {
                      "LeadsynQ’s intuitive task management system categorizes tasks and sends timely alerts, keeping your team organized and sales processes running smoothly."
                    }
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-2">
                <img width={20} src={TickIcon} alt="..." />
                <div>
                  <p className="mb-[8px]  font-semibold">
                    Powerful Insights at Your Fingertips
                  </p>
                  <p className="m-0 text-[#354667] text-[14px]">
                    {
                      "LeadsynQ’s dynamic analytics turn data into actionable insights. Visualize performance and make informed decisions from an intuitive, life-like dashboard."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-[50%] md:block hidden w-[100%]">
            <img src={ReasonImage} alt="import_img" />
          </div>
        </div>
      </div>

      {/* <div className="bg-sky-100  opacity-40 h-[70vh] md:h-[100vh]  "></div> */}
    </div>
  );
};

export default Reasons;
