import React from "react";
import ImportImage from "../../assets/images/effortless.svg";
const ImportExport = () => {
  return (
    <div className="md:mt-[100px]">
      <div className="text-center font-semibold text-[32px]  md:mb-[100px] text-[#047AF8]">
        <p className="">{"Our Features"}</p>
      </div>
      <div className="bg-[#047AF814] relative">
        <p className="m-0 md:w-[85%]  lg:w-[100%] top-[-80px] md:block hidden absolute z-[-1]  md:text-[90px] lg:[100px] xl:[120px] font-bold text-gray-100 text-center">
          Why We're Awesome
        </p>
        <div className="flex md:flex-row flex-col items-center justify-between pb-12 container">
          <div className="flex flex-col w-[90%] md:w-[40%]">
            <p className="mb-[24px] md:text-[40px] text-[24px] md:pt-0 pt-5 font-semibold">
              Effortless Task Control
            </p>
            <p className="m-0 md:text-[20px] text-[16px] text-[#354667]">
              {
                "Track tasks with tabs like To Do, In Progress, and Pending, while automatically updating statuses to ensure timely follow-ups and keep the sales process moving forward."
              }
            </p>
          </div>
          <div className="md:w-[50%] w-[100%]">
            <img src={ImportImage} alt="import_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportExport;
