import React from 'react'
import BgRectangle from '../assets/images/bg_rectangle.png'

const BackgroundRectangle = () => {
    return (

        <div className='relative  md:top-[-71px] h-[100vh] flex justify-center '>
            <div className='w-[50%] h-[100%]'></div>
            <div className='w-[50%] h-[100%]' style={{ backgroundImage: `url(${BgRectangle})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}></div>
        </div>
    )
}

export default BackgroundRectangle
