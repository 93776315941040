import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postAffiliate } from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}


export const fetchPostAffiliate = createAsyncThunk(
    "postAffiliate",
    async (params, { dispatch }) => {
        const response = await postAffiliate(params);

        if (response) {
            dispatch(addPostAffiliate(response));
        }

        return response

    }
)


const postAffiliateSlice = createSlice({
    name: "postAffiliate",
    initialState: initialState,
    reducers: {
        addPostAffiliate: (state, action) => {
            state.storeResponseData = action.payload
        }
    }

});


export default postAffiliateSlice.reducer

export const { addPostAffiliate } = postAffiliateSlice.actions