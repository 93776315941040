import React from 'react'
import BannerSection from './home/banner-section'
import BackgroundRectangle from './background-rectangle'
import TrackingSolution from './home/our-tracking-solution'
import ImportExport from './home/our-features'
import RolesAssignment from './home/prospect-workflow'
import MailRecording from './home/actionable-sales'
import TrackingUpdates from './home/sales-pipeline'
import KPIDashboard from './home/contact-hub'
import KanbanBoard from './home/live-dashboard'
import Performance from './home/comparison-performance'
import Reasons from './home/reasons'
import SubscriptionSection from './home/subscription-section'
import CompareSection from './home/compare-section'
import FAQ from "./home/faq-section"
import DemoBanner from './home/demo-banner'
import CookiesPopup from '../components/cookies/cookies'


const Main = () => {
    return (
        <div className='' >
            <BannerSection />
            <CookiesPopup />
            <BackgroundRectangle />
            <TrackingSolution />
            <ImportExport />
            <RolesAssignment />
            <MailRecording />
            <TrackingUpdates />
            <KPIDashboard />
            <KanbanBoard />
            <Performance />
            <Reasons />
            <SubscriptionSection />
            <CompareSection />
            <FAQ />
            <DemoBanner />
        </div>
    )
}

export default Main