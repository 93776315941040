import React from 'react'

const Card = (props) => {

    const { icon, title, caption, description } = props


    return (
        <div style={{ boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 8px" }} className='flex transition-all hover:scale-[1.02] cursor-pointer flex-col items-center py-[24px] px-[41px] rounded-[16px] bg-white border-1 border-[#F7F8FA] justify-center'>
            <img className='mb-[20px]' width={100} height={100} src={icon} alt="icon" />
            <p className='mb-[8px] font-bold md:text-[24px] text-[18px] text-[#050E21]' >{caption}</p>
            <p className='mb-[8px] text-[14px] text-[#778399]  text-center'>{description}</p>
        </div>
    )
}

export default Card
