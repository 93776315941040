import React, { useEffect, useState } from "react";

// **************************Import Components*************************
import BlogList from "./blog-list";
import { Bg } from "./blogs";
import PopularBlogs from "./popular-blogs";
import SearchBlogs from "./category-search";

// **************************Import Redux*****************************
import { getBlogData } from "../../api/list";

// **************************Import API********************************
import { getBlogCategories } from "../../api/list";

// **************************Import Packages****************************
import { MdArrowBack } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonLoader from "react-loading-skeleton";

const Blogs = () => {
  // const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState({});
  const [PopularData, setPopularData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loader, setLoader] = useState(false);

  console.log(groupedData, filterValue, "data777");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  useEffect(() => {
    if (category) {
      setFilterValue((prevSelectedCategories) => {
        let updatedCategories;
        if (prevSelectedCategories.includes(category)) {
          updatedCategories = prevSelectedCategories.filter(
            (cat) => cat !== category
          );
        } else {
          updatedCategories = [...prevSelectedCategories, category];
        }
        const uniqueCategories = [...new Set(updatedCategories)];
        const filterValues = uniqueCategories.join(", ");
        getBlogData({ category: filterValues, isEnabled: true })
          .then((value) => {
            const sorted = value?.data?.records.sort(
              (a, b) => b.createdAt - a.createdAt
            );
            setData(sorted);
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
        return updatedCategories;
      });
    } else {
      setLoader(true);
      getBlogData({ isEnabled: true })
        .then((value) => {
          const sorted = value?.data?.records.sort(
            (a, b) => b.createdAt - a.createdAt
          );
          setData(sorted);
          setPopularData(sorted);
          setLoader(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
      getBlogCategories().then((res) => {
        setCategoriesData(res?.data?.records);
      });
    }
  }, [category]); // router?.query?.category

  const fetchData = () => {
    setLoader(true);
    setData([]);
    setFilterValue([]);
    getBlogData({ isEnabled: true })
      .then((value) => {
        const sorted = value?.data?.records.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setLoader(false);
        setData(sorted);
        setPopularData(sorted);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
    getBlogCategories().then((res) => {
      setCategoriesData(res?.data?.records);
    });
  };

  const handleCategoryClick = (e, category) => {
    e.stopPropagation();

    setFilterValue((prevSelectedCategories) => {
      let updatedCategories;
      if (prevSelectedCategories.includes(category)) {
        updatedCategories = prevSelectedCategories.filter(
          (cat) => cat !== category
        );
      } else {
        updatedCategories = [...prevSelectedCategories, category];
      }
      const uniqueCategories = [...new Set(updatedCategories)];
      const filterValues = uniqueCategories.join(", ");
      getBlogData({ category: filterValues, isEnabled: true })
        .then((value) => {
          const sorted = value?.data?.records.sort(
            (a, b) => b.createdAt - a.createdAt
          );
          setData(sorted);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
      return updatedCategories;
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      const grouped = groupByCategory(data);
      setGroupedData(grouped);
      const initialItemsToShow = {};
      Object.keys(grouped).forEach((category) => {
        initialItemsToShow[category] = filterValue.length === 0 ? 5 : 6;
      });
      setItemsToShow(initialItemsToShow);
    }
  }, [data]);

  const groupByCategory = (data) => {
    return data.reduce((acc, item) => {
      const { category } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  };

  const handleSeeMore = (category) => {
    setItemsToShow((prevItemsToShow) => ({
      ...prevItemsToShow,
      [category]: prevItemsToShow[category] + 3,
    }));
  };

  const handleSeeLess = (category) => {
    setItemsToShow((prevItemsToShow) => ({
      ...prevItemsToShow,
      [category]: filterValue.length === 0 ? 5 : 6,
    }));
  };

  const navigate = useNavigate();

  const BlogDetailsRoute = (value) => {
    navigate(value);
  };

  return (
    <div>
      <Bg className="">
        <div className="bg-[#0F337C] h-[220px]  flex justify-center items-center">
          <div className="container">
            <div className="text-white font-bold text-3xl text-center">
              Blogs{" "}
            </div>
            <p className="text-white font-normal text-sm text-center pt-3">
              The thurst for knowledge is Quenched. Enjoy our latest blogs here.
            </p>
          </div>
        </div>
        <div className="container py-4 ">
          {/* Categories filter Section */}
          {category ? (
            <div
              onClick={() => {
                BlogDetailsRoute("/blogs");
                fetchData();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center mt-3"
              >
                <div className="fw-bold align-middle">
                  {" "}
                  <MdArrowBack className="fs-4" />
                </div>
                <div className="fw-bold align-middle mx-2">Back to Blogs</div>
              </div>
            </div>
          ) : (
            <section className="categories">
              {loader ? null : data?.length !== 0 ? (
                <div>
                  <div>
                    <div className="categories-filter mt-3">
                      Filter by category
                    </div>
                  </div>
                  <div className="categories-list">
                    <div
                      className={`${
                        filterValue.length === 0 ? "selected-categories" : ""
                      } categories-item text-capitalize`}
                      onClick={() => {
                        setFilterValue([]);
                        fetchData();
                      }}
                    >
                      All
                    </div>
                    {categoriesData.map((item, index) => {
                      return (
                        <div
                          className={`categories-item text-capitalize ${
                            filterValue.includes(item.title)
                              ? "selected-categories"
                              : ""
                          }`}
                          key={index}
                          onClick={(e) => handleCategoryClick(e, item.title)}
                        >
                          {item?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </section>
          )}

          {filterValue.length === 0 ? (
            <div>
              {loader ? (
                <div className="w-100 d-flex my-40 justify-content-center align-items-center loader-height">
                  <span className="">
                    <svg
                      aria-hidden="true"
                      className="pending-spin-loader"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#f2f6ff"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="4233b0"
                      />
                    </svg>{" "}
                  </span>
                </div>
              ) : (
                <div>
                  {!loader && data?.length === 0 ? (
                    <div className="mt-10">
                      <div className="d-flex justify-content-center">
                        <img
                          src="/assets/no-records.png"
                          alt="no records"
                          className="img-fluid "
                          width={250}
                          height={250}
                        />
                      </div>
                      <div className="text-center mt-4">
                        <span className="fs-5 fw-semibold record text-capitalize">
                          No Blogs Available
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* Popular blogs Section */}
                      <PopularBlogs PopularData={PopularData} />
                      {/* Complete Blog Listing By Categories */}
                      <BlogList
                        handleSeeLess={handleSeeLess}
                        handleSeeMore={handleSeeMore}
                        itemsToShow={itemsToShow}
                        groupedData={groupedData}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : filterValue.length !== 0 ? (
            <SearchBlogs
              data={data}
              groupedData={groupedData}
              handleSeeLess={handleSeeLess}
              handleSeeMore={handleSeeMore}
              itemsToShow={itemsToShow}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              fetchData={fetchData}
            />
          ) : null}
        </div>
      </Bg>
    </div>
  );
};

export default Blogs;
