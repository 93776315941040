import { hostConfig } from "../config/index";
import { URL_CONSTANTS } from "../api/urls";

const errorHandler = (error) => error;

export const postDataApi = (requestUrl, params, service) => {
  return fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json();
      }

      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDataApi = (requestUrl, params, service) => {
    const token = localStorage.getItem("token");
  return fetch(`${hostConfig.API_URL}${requestUrl}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json();
      }

      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getListByApi = (requestUrl, params) => {
  let getParams = "?";

  //check all the params
  
  if (
    params &&
    params.limit &&
    params.limit !== null &&
    params.limit !== undefined
  ) {
    getParams += `&limit=${params.limit}`;
  }
  if (
    params &&
    params.isEnabled &&
    params.isEnabled !== null &&
    params.isEnabled !== undefined
  ) {
    getParams += `&isEnabled=${params.isEnabled}`;
  }
  if (
    params &&
    params.category &&
    params.category !== null &&
    params.category !== undefined
  ) {
    getParams += `&category=${params.category}`;
  }
  if (
    params &&
    params.lastKey &&
    params.lastKey !== null &&
    params.lastKey !== undefined
  ) {
    getParams += `&lastKey=${params.lastKey}`;
  }
  if (
    params &&
    params.firstKey &&
    params.firstKey !== null &&
    params.firstKey !== undefined
  ) {
    getParams += `&firstKey=${params.firstKey}`;
  }
  if (
    params &&
    params.createdAt &&
    params.createdAt !== null &&
    params.createdAt !== undefined
  ) {
    getParams += `&createdAt=${params.createdAt}`;
  }
  if (
    params &&
    params.previous &&
    params.previous !== null &&
    params.previous !== undefined
  ) {
    getParams += `&previous=${params.previous}`;
  }

  if (
    params &&
    params.currentPage &&
    params.currentPage !== null &&
    params.currentPage !== undefined
  ) {
    getParams += `&page=${params.currentPage}`;
  }

  if (
    params &&
    params.sort &&
    params.sort !== null &&
    params.sort !== undefined
  ) {
    getParams += `&sort=${params.sort}`;
  }

  if (params && params._id && params._id !== null && params._id !== undefined) {
    getParams += `&_id=${params._id}`;
  }

  if (
    params &&
    params.isActive !== null &&
    params.isActive !== "" &&
    params.isActive !== undefined
  ) {
    getParams += `&isActive=${params.isActive}`;
  }

  if (
    params &&
    params.userId &&
    params.userId !== null &&
    params.userId !== undefined
  ) {
    getParams += `&userId=${params.userId}`;
  }

  if (
    params &&
    params.query &&
    params.query !== null &&
    params.query !== undefined
  ) {
    getParams += `&query=${params.query}`;
  }
  if (
    params &&
    params.location &&
    params.location !== null &&
    params.location !== undefined
  ) {
    getParams += `&location=${params.location}`;
  }
  if (
    params &&
    params.search &&
    params.search !== null &&
    params.search !== undefined
  ) {
    getParams += `&search=${params.search}`;
  }
 

  return fetch(`${hostConfig.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  })
    // .then((response) => {
    //   return responseStatusHandler(response);
    // })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/** ****************************** View Api *********************************** */
export const viewDataByApi = (requestUrl, dataId) => {
  let viewToken = localStorage.getItem("token");
  return fetch(`${hostConfig.API_URL}${requestUrl}/${dataId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${viewToken}`,
    },
  })
    .then((result) =>
      result.status === 200 || result.status === 201 || result.status === 400
        ? result.json()
        : result
    )
    .catch((error) => {
      errorHandler(error);
    });
};
