import React, { useState } from "react";
import { useFormik } from "formik";
import BlobImage from "../../assets/images/Blob.png";
import * as Yup from "yup";
import Button from "../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchPostAffiliate } from "../../redux/slices/post-affiliate.slice";
import ThanksPopup from "../../components/thank-you-popup";
import metaIcon from "../../assets/images/metaIcon.png";
import { CircleSpinner } from "react-spinners-kit";
import Styles from "./enquiry.module.css";
import PhoneNumber from "../../components/phone-number-input";
import { PiX } from "react-icons/pi";
import { fetchPostContact } from "../../redux/slices/post-contact.slice";
import { useNavigate, useParams } from "react-router-dom";

const Enquiry = () => {
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const navigate = useNavigate();

  const handleHome = (value) => {
    navigate(`/`);
    window.scrollTo(0, 0);
  };
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      message: Yup.string(),
    }),

    onSubmit: (values) => {
      values.from = "enquiry";
      setButtonLoader(true);
      dispatch(fetchPostContact(values)).then((res) => {
        setButtonLoader(false);
        formik.resetForm();
        setShowThanksPopup(true);
      });
    },
  });

  const numberValidate = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 20);
    formik.setFieldValue(name, numericValue);
  };

  return (
    <div className="container mt-[80px]">
      <div
        className="md:w-[50%] mx-[auto]"
        style={{
          backgroundImage: `url(${BlobImage})`,

          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-[#0F337C] p-8 rounded-lg  mb-[16px]">
          <p className="md:mb-[46px] text-[#FFFFFF] text-center text-[40px] font-semibold">
            For Enquiry{" "}
          </p>

          <form
            className=" w-full md:w-[80%] mx-auto flex gap-[20px]  flex-col items-center"
            action=""
            onSubmit={formik.handleSubmit}
          >
            <div className="w-[100%]">
              <p className="font-medium  text-[#FFFFFF] m-0">Name*</p>
              <input
                className={`mt-1 px-2 h-[50px] 
                                    w-[100%] rounded`}
                type="text"
                name="name"
                placeholder="Enter your name"
                onChange={formik.handleChange}
                value={formik.values.name}
                style={{
                  border:
                    formik.errors.name && formik.touched.name
                      ? "1px solid red"
                      : "",
                }}
              />
              <div>
                {" "}
                {formik.errors.name && formik.touched.name ? (
                  <div className="">
                    <span className="text-red-400 text-[16px]">
                      {formik.errors.name}{" "}
                    </span>
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
            </div>

            <div className="w-[100%]">
              <p className="font-medium m-0 text-[#FFFFFF]  ">Email*</p>
              <input
                className={`px-2 h-[50px] mt-1 w-[100%] rounded`}
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                value={formik.values.email}
                style={{
                  border:
                    formik.errors.email && formik.touched.email
                      ? "1px solid red"
                      : "",
                }}
              />

              <div>
                {" "}
                {formik.errors.email && formik.touched.email ? (
                  <div className="">
                    <span className="text-red-400 text-[16px]">
                      {formik.errors.email}{" "}
                    </span>
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
            </div>

            <div className="mobile-field w-[100%]">
              <p className="font-medium m-0 text-[#FFFFFF]  ">Phone No*</p>
              <PhoneNumber
                onBlur={(e) => {
                  const formattedNumber = formik.values.phone.replace(
                    /^\+/,
                    ""
                  );
                  formik.setFieldValue("phone", formattedNumber);
                  formik.handleBlur(e);
                }}
                placeholder="Enter your phone.no"
                errorInput={formik.errors?.phone}
                value={formik.values?.phone}
                name="phone"
                onChange={(value, country, e, formattedValue) => {
                  formik.handleChange(formattedValue);
                  formik.setFieldValue("phone", formattedValue);
                }}
                height="50px"
              />
              <div>
                {" "}
                {formik.errors.phone && formik.touched.phone ? (
                  <div className="">
                    <span className="text-red-400 text-[16px]">
                      {formik.errors.phone}{" "}
                    </span>
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
            </div>

            <div className="w-[100%]">
              <p className="font-medium m-0 text-[#FFFFFF]  ">Message</p>

              <textarea
                className={`mt-1  p-2 rounded w-[100%] resize-none`}
                cols={30}
                rows={3}
                name="message"
                placeholder="Enter your Message..."
                id=""
                onChange={formik.handleChange}
                value={formik.values.message}
              ></textarea>
            </div>
            <div className="mt-[20px] ">
              <Button
                type={"submit"}
                disabled={buttonLoader}
                onClick={formik.handleSubmit}
                btnName={
                  buttonLoader ? (
                    <span className="d-flex">
                      {" "}
                      <span
                        style={{
                          position: "relative",
                          left: "-10px",
                        }}
                      >
                        <CircleSpinner
                          className={{ paddingLeft: "10px" }}
                          size={18}
                          color="#0F337C"
                        />
                      </span>{" "}
                      Submit
                    </span>
                  ) : (
                    <span>Submit</span>
                  )
                }
                btnStyle="bg-[#ffffff] text-[#0F337C] px-[50px] sm:px-[150px]  py-[10px] rounded"
              />
            </div>
          </form>
        </div>
      </div>

      <ThanksPopup
        showThanksPopup={showThanksPopup}
        setShowThanksPopup={setShowThanksPopup}
        dialogClassName={"popup-size"}
      >
        <div className="bg-white flex items-center flex-col sm:py-[30px] py-[10px] ">
          <img src={metaIcon} alt="..." className="logo-responsive"  />

          <p className="text-[#0F337CD9]  m-0 font-semibold text-[28px] sm:text-[60px]">
            {" "}
            Thank you!
          </p>
          {/* <div className="md:w-[20%] my-3 h-[6px] bg-[#0F337C]  rounded-full"></div> */}

          <p className="text-[#0F337CD9]  pt-2 mb-0  text-[14px] sm:text-[20px] font-medium ">
            Your enquiry has been submitted
          </p>
          <div className="mt-[30px]" onClick={() => setShowThanksPopup(false)}>
            <Button
              btnName={"Close"}
              btnStyle="cursor-pointer bg-[#0F337C] py-[3px] sm:py-[7px] px-[25px] rounded-[8px] text-[15px] sm:text-[15px] text-white"
              onClick={(e) => handleHome(e)}
            />
          </div>
        </div>
      </ThanksPopup>
    </div>
  );
};

export default Enquiry;
