import React, { useState } from "react";
import ProIcon from "../../assets/icons/proIcon.png";
import CustomIcon from "../../assets/icons/custom-icon.svg";
import BasicIcon from "../../assets/icons/basic.svg";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import CenterPopup from "../../components/center-popup";

const SubscriptionSection = () => {

  const data = useSelector(
    (state) => state.getSubscriptions?.storeResponseData?.data
  );
  console.log(data, "4543543");

  const records = data?.records || [];
  // Add custom subscription if needed
  const updatedRecords = [...records, { subscriptionName: "custom" }];


  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(1)

  function formatNumberWithCommas(amount) {
    let str = amount.toString();
    let result = "";

    let length = str.length;

    if (length <= 3) {
      return str;
    } else if (length === 4) {
      result = str[0] + "," + str.slice(1);
    } else if (length === 5) {
      result = str[0] + str[1] + "," + str.slice(2);
    } else {
      result = str[0] + str[1] + str[2] + "," + str.slice(3);
    }

    return result;
  }
  const [isYearly, setIsYearly] = useState(false);

  const handleToggleChange = () => {
    setIsYearly(!isYearly);
  };
  const handleTab = (val) => {
    setTab(val)
  }

  return (
    <div className="container md:mt-[60px] mt-[20px]" id="pricing">
      <div className="text-center">
        <p className="m-0 md:text-[40px] text-[30px] font-bold">
          Take your startup{" "}
          <span
            style={{
              backgroundImage:
                "linear-gradient(266.01deg, #55CBFB -21.73%, #1D4ED8 96.22%)",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {"further, faster"}
          </span>
        </p>
        <p className="my-[14px] text-[#888888]">
          {"Choose a plan that’s right for you"}
        </p>
      </div>

      {/* subscription cards */}
      {/* <div >
        <div className="flex justify-center">
          <div>
            <button onClick={() => {
              handleTab(1)
            }} className={`${tab === 1 ? "bg-[#0F337C] text-[#FFFFFF]" : "bg-[#DDDDDD] text-[#000000]"}  px-3 py-3 rounded-sm`}>Monthly</button>
          </div>

          <div>
            <button onClick={() => {
              handleTab(2)
            }} className={`${tab === 2 ? "bg-[#0F337C] text-[#FFFFFF]" : "bg-[#DDDDDD] text-[#000000]"}  px-3 py-3 rounded-sm`}>Yearly</button>
          </div>
        </div>
      </div> */}
      <p className="d-flex justify-content-center align-items-center pb-3">
        <span className=" custom-year text-[16px] font-medium leading-5">Monthly </span>
        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            onChange={handleToggleChange}
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className=" custom-year text-[16px] font-medium leading-5" >Yearly</span>
      </p>

      <div className="grid md:grid-cols-3 md:px-[100px] grid-cols-1  mt-[40px]">
        {updatedRecords.map((item, i) => {
          return (
            <div
              className={` mb-[20px] cursor-pointer mx-auto w-[80%] subscriptionCard py-[45px] px-[35px] gap-[15px] md:w-[90%] ${item?.premium === true
                ? "bg-[#0F337C] relative text-white border-[#778399] "
                : "border-[#C0C3CC] border-1 bg-white"
                }   rounded-[12px]  flex items-center flex-col justify-between`}
            >
              <div
                className={` ${item?.premium === true ? "absolute" : "hidden"
                  } top-[-16px]`}
              >
                <p className="m-0 text-[#0F337C] py-[8px] px-[16px] text-[12px] font-semibold border-1 border-[#0F337C] bg-white rounded-[30px] ">
                  Popular
                </p>
              </div>

              <div className="flex items-center gap-[12px] ">
                <img
                  width={52}
                  src={
                    item?.premium === true
                      ? ProIcon
                      : i === 2
                        ? CustomIcon
                        : BasicIcon
                  }
                  alt=""
                />
                <p
                  className={` ${item?.premium === true ? "text-white" : "text-[#0F337C]"
                    } capitalize m-0 text-[24px] font-bold `}
                >
                  {" "}
                  {item?.subscriptionName}
                </p>
              </div>
              {item?.subscriptionName === "Custom" ||
                item?.subscriptionName === "custom" ? (
                <div className="text-center text-lg font-normal md:w-[80%] m-0 flex items-center justify-center">
                  <p className="m-0">
                    To configure your Custom Plan, please contact our admin
                    team.
                  </p>
                </div>
              ) : (
                <div className="">
                  <p className="m-0 text-[50px] text-center font-semibold">

                    ₹ {(formatNumberWithCommas(item?.monthlyPrice ? (isYearly ? item?.yearlyPrice : item?.monthlyPrice) : item?.price))}
                    {" "}
                  </p>

                  <p
                    className={`font-semibold ${item?.premium === true ? "text-white" : "text-[#000]"
                      } text-center m-0 text-[16px]`}
                  >
                    +18% GST{" "}
                    <span
                      className={
                        item?.premium === true
                          ? "text-[#DDDDDD]"
                          : "text-[#666666]"
                      }
                    >
                      / User
                    </span>
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <div onClick={() => setShow(!show)}>
          <Button
            btnName="Contact us"
            btnStyle="cursor-pointer bg-[#0F337C] py-[10px] px-[28px] mt-[20px] rounded-[8px] text-[16px] text-[#fff] font-medium"
          />
        </div>
      </div>
      <CenterPopup show={show} setShow={setShow} />
    </div>
  );
};

export default SubscriptionSection;
