import { postDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postAffiliate = (params) => {
    return postDataApi(URL_CONSTANTS.affiliate, params);
}


export const postContact = (params) => {
    return postDataApi(URL_CONSTANTS.contact, params);
}

export const postBookings = (params) => {
    return postDataApi(URL_CONSTANTS.book_demo, params);
}
