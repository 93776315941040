import styled from "styled-components";
export const Bg = styled.div`
  // padding-top: 1rem;
  .blog-title {
    font-size: 20px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; /* Number of lines to show before adding ellipsis */
    height: 42px; /* (line height) * (number of lines) */
  }
  .feature-heading {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #0F337C !important;
  }
  .feature-title {
    font-size: 16px !important;
    height: 30px !important;
  }
  .author-name {
    font-weight: 500;
    font-size: 16px;
    color: #0b1d1299;
  }
  @media screen and (max-width: 992px) {
    .feature-title {
      font-size: 14px !important;
      height: 30px !important;
    }
    .blog-title {
      font-size: 16px !important;
      height: 30px !important;
    }
    .author-name {
      font-weight: 500;
      font-size: 14px;
      color: #0b1d1299;
    }
    .readmore {
      font-size: 14px;
    }
    .categories-item {
      border: 1px solid #aaabad;
      display: inline-block;
      font-size: 12px;
      border-radius: 18px !important;
      padding: 5px 10px 5px 10px !important;
      margin-right: 15px !important;
      margin-bottom: 5px !important;
      margin-top: 10px !important;
      color: #000713;
      cursor: pointer;
    }
  }

  .feature-title:hover {
    color: #047AF8 !important;
  }
  .details-page {
    padding-top: 2rem !important;
  }
  .form-head {
    color: #0F337C !important;
  }
  .date {
    color: #6d7771;
  }
  .align-details {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  @media screen and (max-width: 990px) {
    .align-details {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
  }
  @media screen and (max-width: 992px) {
    .popular-responsive {
      margin-top: 50px !important;
    }
  }
  .details {
    color: rgba(8, 20, 41, 0.9);
    font-size: 16px;
    line-height: 24px; /* 150% */
    height: 45px;
  }
  .blog-category {
    color: #0F337C !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .date {
    font-size: 14px !important;
  }
  // .card-contain {
  //   width: 90% !important;
  //   margin: 0 auto;
  // }
  .card-style {
    border-radius: 12px;
    background: #fff;
    border: 2px solid rgba(11, 29, 18, 0.1) !important;
  }
  .categories-filter {
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .categories-item {
    border: 1px solid #aaabad;
    display: inline-block;
    font-size: 14px;
    border-radius: 18px !important;
    padding: 8px 20px 8px 20px ;
    margin-right: 15px !important;
    margin-bottom: 5px !important;
    margin-top: 10px !important;
    color: #000713;
    cursor: pointer;
  }
  .popular-blogs-heading {
    font-weight: 600 !important;
    font-size: 24px !important;
    color: #0F337C !important;
  }
  .popular-blog-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #989898;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Number of lines to show before adding ellipsis */
    height: 40px; /* (line height) * (number of lines) */
  }
  .categories-item:hover {
    background: #0F337C !important;
    color: #fff !important;
    transition: 0.5s;
  }
  .selected-categories {
    background: #0F337C !important;
    color: #fff !important;
  }
  .blogs-container .row > * {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .blog-card {
    border-radius: 12px !important;
  }
  .let-one {
    width: 100%;
    height: 250px;
    top: 67px !important;
    position: relative;
    overflow: hidden;
    align-items: center;
    display: flex;
    background-image: url("/assets/contact-us.webp");
    background-repeat: no-repeat;
    background-size: cover;
    /* background: rgba(239, 206, 30, 0.9); */
  }
  .readmore {
    display: flex !important;
    position: relative;
    color: rgba(8, 20, 41, 0.6);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      color: #047AF8 !important;
      font-weight: 600 !important;
      .VectorImage {
        display: block;
      }
    }
    .VectorImage {
      position: absolute;
      bottom: -6px;
      // margin-top: -15px; 
      display: none;
    }
    .VectorImage-one {
      margin-top: -19px;
    }
  }
`;
export const SecondCard = styled.div`
  margin-top: 10%;
  h1 {
    font-size: 12px;
  }
  .card-two {
    background: #ffffff;
    border: 1px solid rgba(8, 20, 41, 0.2);
    border-radius: 16px;
    padding: 0px;
  }
  .card-img {
    border-radius: 16px 16px 0px 0px;
  }
`;
