import React from 'react'

import PartnerBanner from "./partner-banner"
import PartnerForm from "./form"

const PartnerWithUs = () => {
    return (
        <div >
            <PartnerBanner />
            <PartnerForm />
        </div>
    )
}

export default PartnerWithUs
