import React from "react";
import { hostConfig } from "../../config";
import { BsArrowRight } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { formattedDate } from "../../utils/date-format";
import { useNavigate } from "react-router-dom";

function BlogList(props) {
  const { groupedData, handleSeeMore, handleSeeLess, itemsToShow } = props;
  const regex = /(<([^>]+)>)/gi;
  const regexAmp = /&amp;/g;
  const regexnsbp = /&nbsp;|&nsbp;/g;
  const regexGreater = /&lt;/g;
  const regexSmaller = /&gt;/g;

  const navigate = useNavigate();

  const BlogDetailsRoute = (value) => {
    navigate(value);
  };

  return (
    <div>
      {Object.keys(groupedData).map((category) => (
        <div key={category}>
          <div className="popular-blogs-heading text-capitalize">
            {category}
          </div>
          <div className="blog-categories-listing">
            <div className="row mb-5 ">
              {groupedData[category]
                ?.filter((item) => !item?.featured)
                ?.slice(
                  0,
                  groupedData[category]?.filter((item) => !item?.featured)
                    .length === 0
                    ? 3
                    : 2
                )
                .map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 my-3 cursor-pointer"
                    key={index}
                  >
                    <div
                      onClick={() => {
                        BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                      }}
                    >
                      <img
                        src={`${hostConfig.IMAGE_URL}blog/${item?.filename}.png`}
                        alt="img"
                        className="img-fluid"
                        style={{
                          width: "100%",
                          height: "278px",
                        }}
                      />
                      <div className="d-flex justify-content-between">
                        <div className="pt-2 pb-2 blog-category text-capitalize">
                          {item?.category.length > 20
                            ? item?.category?.slice(0, 20) + "..."
                            : item?.category}
                        </div>
                        <div className="d-flex align-items-center pb-2 pt-2">
                          <MdDateRange className="align-middle date" />
                          <div className="align-middle date ps-2 font-semibold">
                            {formattedDate(item?.createdAt)}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ height: "auto" }}
                        className="mb-0 pt-1 blog-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
                      >
                        {item?.title}{" "}
                      </div>
                      <div className="popular-blog-description">
                        {" "}
                        {item?.summary
                          ? item?.summary
                              .replace(regex, "")
                              .replace(regexAmp, "&")
                              ?.replace(regexnsbp, " ")
                              .replace(regexGreater, "<")
                              .replace(regexSmaller, ">")
                          : item?.summary
                              .replace(regex, "")
                              .replace(regexAmp, "&")
                              ?.replace(regexnsbp, " ")
                              .replace(regexGreater, "<")
                              .replace(regexSmaller, ">")}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="text-capitalize author-name">
                          {item?.authorName}
                        </div>
                        <div>
                          <div className="readmore cursor ">
                            Read more
                            <BsArrowRight className="fs-4 ms-2" />
                            <div className="VectorImage">
                              <img src="/assets/VectorImage.svg" alt="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {groupedData[category].some((item) => item?.featured) &&
              groupedData[category].length > 3 ? (
                <div className="col-lg-4 col-md-6 col-sm-12  my-3">
                  <div>
                    <h5 className="feature-heading">
                      Featured in{" "}
                      <span className="text-capitalize feature-heading">
                        {category}
                      </span>
                    </h5>
                  </div>
                  {groupedData[category]
                    ?.filter((item) => item?.featured)
                    ?.slice(0, 4)
                    ?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                        }}
                      >
                        <div className="cursor-pointer">
                          <div className="border-bottom mb-2 mt-3"></div>
                          <div
                            style={{ height: "auto" }}
                            className="mb-0 my-1 pt-1 feature-title blog-title pe-1 pb-2 text-capitalize font-semibold"
                          >
                            {item?.title}{" "}
                          </div>
                          <div className="popular-blog-description">
                            {" "}
                            {item?.summary
                              ? item?.summary
                                  .replace(regex, "")
                                  .replace(regexAmp, "&")
                                  ?.replace(regexnsbp, " ")
                                  .replace(regexGreater, "<")
                                  .replace(regexSmaller, ">")
                              : item?.summary
                                  .replace(regex, "")
                                  .replace(regexAmp, "&")
                                  ?.replace(regexnsbp, " ")
                                  .replace(regexGreater, "<")
                                  .replace(regexSmaller, ">")}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}

              {groupedData[category]
                ?.filter((item) => !item?.featured)
                ?.slice(2, itemsToShow[category])
                ?.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12  my-3 "
                    key={index}
                  >
                    <div className="cursor-pointer">
                      <img
                        src={`${hostConfig.IMAGE_URL}blog/${item?.filename}.png`}
                        alt="img"
                        className="img-fluid"
                        style={{
                          width: "100%",
                          height: "278px",
                        }}
                      />
                      <div className="d-flex justify-content-between">
                        <div className="pt-2 pb-2 blog-category text-capitalize">
                          {item?.category.length > 20
                            ? item?.category?.slice(0, 20) + "..."
                            : item?.category}
                        </div>
                        <div className="d-flex align-items-center pb-2 pt-2">
                          <MdDateRange className="align-middle date" />
                          <div className="align-middle date ps-2 font-semibold">
                            {formattedDate(item?.createdAt)}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ height: "auto" }}
                        className="mb-0 pt-1 blog-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
                      >
                        {item?.title}{" "}
                      </div>
                      <div className="popular-blog-description">
                        {" "}
                        {item?.summary
                          ? item?.summary
                              .replace(regex, "")
                              .replace(regexAmp, "&")
                              ?.replace(regexnsbp, " ")
                              .replace(regexGreater, "<")
                              .replace(regexSmaller, ">")
                          : item?.summary
                              .replace(regex, "")
                              .replace(regexAmp, "&")
                              ?.replace(regexnsbp, " ")
                              .replace(regexGreater, "<")
                              .replace(regexSmaller, ">")}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="text-capitalize author-name">
                          {item?.authorName}
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                            }}
                            className="readmore cursor "
                          >
                            Read more
                            <BsArrowRight className="fs-4 ms-2" />
                            <div className="VectorImage">
                              <img src="/assets/VectorImage.svg" alt="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {groupedData[category].filter((item) => !item?.featured).length >
              itemsToShow[category] ? (
                <div className="col-12 text-center">
                  <div
                    onClick={() => handleSeeMore(category)}
                    className="categories-item"
                  >
                    See More
                  </div>
                </div>
              ) : groupedData[category].filter((item) => !item?.featured)
                  .length > 6 ? (
                <div className="col-12 text-center">
                  <div
                    onClick={() => handleSeeLess(category)}
                    className="categories-item"
                  >
                    See Less
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BlogList;
