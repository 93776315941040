import { getDataApi, getListByApi, viewDataByApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const getSubscriptions = (params) => {
  return getDataApi(URL_CONSTANTS.subscription, params);
};

export const getBlogData = (params) => {
  return getListByApi(URL_CONSTANTS.blogs, params);
};

export const getBlogCategories = (params) => {
  return getDataApi(URL_CONSTANTS.blogsCategories, params);
};

export const getBlogDataById = (id) => {
  return viewDataByApi(URL_CONSTANTS.blogs, id);
};
