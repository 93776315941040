import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postContact } from "../../api/create";


const initialState = {
    loading: false,
    storeResponseData: [],
    error: null
}


export const fetchPostContact = createAsyncThunk(
    "postContact",
    async (params, { dispatch }) => {
        const response = await postContact(params);

        if (response) {
            dispatch(addPostContact(response));
        }

        return response

    }
)


const postContactSlice = createSlice({
    name: "postAffiliate",
    initialState: initialState,
    reducers: {
        addPostContact: (state, action) => {
            state.storeResponseData = action.payload
        }
    }

});


export default postContactSlice.reducer

export const { addPostContact } = postContactSlice.actions