import React, { useEffect, useState } from "react";
import { Bg } from "./blogs";
import { formattedMonthViewDate } from "../../utils/date-format";
// import { getBlogData, getBlogDataById } from "../../redux/actions/blog";
import { hostConfig } from "../../config";
import { MdArrowBack } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { formattedDate } from "../../utils/date-format";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogData, getBlogDataById } from "../../api/list";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoader(true);
      getBlogDataById(id)
        .then((res) => {
          setLoader(false);
          setBlogs(Array.isArray(res?.data?.Items) ? res?.data?.Items : []);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    getBlogData()
      .then((value) => {
        const sorted = value?.data?.records.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setData(sorted);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const filtered = data.filter(
    (item) => item?.category === blogs[0]?.category && item?.id !== blogs[0]?.id
  );

  const navigate = useNavigate();
  
  const handleSearchData = (value) => {
    navigate(`/blogs/?category=${value}`);
  };

  const BlogDetailsRoute = (value) => {
    navigate(value);
  };

  const regex = /(<([^>]+)>)/gi;
  const regexAmp = /&amp;/g;
  const regexnsbp = /&nbsp;|&nsbp;/g;
  const regexGreater = /&lt;/g;
  const regexSmaller = /&gt;/g;

  return (
    <div className="border-t">
      <Bg>
        {loader ? (
          <div
            className="container align-details "
            style={{ marginTop: "500px" }}
          >
            Loading...
          </div>
        ) : (
          <div className="container align-details ">
            {blogs.map((blog, index) => (
              <div key={index} className="pb-1 pb-lg-3 details-page">
                <div
                  className="backToBlog pb-3.5"
                  onClick={() => {
                    BlogDetailsRoute(`/blogs`);
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex align-items-center"
                  >
                    <div className="font-semibold align-middle">
                      {" "}
                      <MdArrowBack className="fs-4" />
                    </div>
                    <div className="font-semibold align-middle mx-2">
                      Back to Blogs
                    </div>
                  </div>
                </div>
                <h4
                  className="form-head text-[18px] font-semibold text-capitalize"
                  style={{ wordBreak: "break-word" }}
                >
                  {blog?.category}
                </h4>

                <h2
                  className="sub-title heading text-capitalize"
                  style={{ wordBreak: "break-word" }}
                >
                  {blog?.title}
                </h2>
                <div className="d-flex items-center text-center gap-4">
                  <div className="d-flex align-items-center justify-content-center text-center">
                    <img
                      src="/assets/profile-avatar.png"
                      alt="img"
                      className="img-fluid align-middle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <p
                      className="align-middle text-center mt-3 mx-2 fw-semibold text-capitalize"
                      style={{ color: "#778399" }}
                    >
                      {blog?.authorName.length > 15
                        ? blog?.authorName.slice(0, 15) + "..."
                        : blog?.authorName}
                    </p>
                  </div>
                  <p
                    className="mt-3 align-middle fw-normal"
                    style={{ color: "#778399", fontSize: "14px" }}
                  >
                    {formattedMonthViewDate(blog?.createdAt)}
                  </p>
                </div>
                <div className="py-4">
                  <img
                    src={`${hostConfig.IMAGE_URL}blog/${blog?.filename}.png`}
                    alt="img"
                    className="img-fluid"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                      borderRadius: "18px",
                    }}
                  />
                </div>
                <div className="blog-details-summary">
                  <div
                    style={{ wordBreak: "break-word" }}
                    className="blog-css"
                    dangerouslySetInnerHTML={{
                      __html: blog?.summary,
                    }}
                  ></div>
                </div>
              </div>
            ))}
            <div>
              {filtered?.length > 0 ? (
                <div className="popular-blogs-heading my-3">Similar Blogs</div>
              ) : null}
              <div className="row mb-5 ">
                {filtered?.length > 0
                  ? filtered.slice(0, 2).map((item, index) => (
                      <div
                        className="col-lg-6 col-sm-12 mb-3 px-3"
                        key={index}
                        onClick={() => {
                          BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                        }}
                      >
                        <div className="cursor-pointer">
                          <img
                            src={`${hostConfig.IMAGE_URL}blog/${item?.filename}.png`}
                            alt="img"
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "278px",
                            }}
                          />
                          <div className="d-flex justify-content-between">
                            <div className="pt-2 pb-2 blog-category text-capitalize">
                              {item?.category.length > 20
                                ? item?.category.slice(0, 20) + "..."
                                : item?.category}
                            </div>
                            <div className="d-flex align-items-center pb-2 pt-2">
                              <MdDateRange className="align-middle date" />
                              <div className="align-middle date ps-2 font-semibold">
                                {formattedDate(item?.createdAt)}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ height: "auto" }}
                            className="mb-0 pt-1 blog-title pe-1 pe-4 pb-2 text-capitalize font-semibold"
                          >
                            {item?.title}{" "}
                          </div>
                          <div className="popular-blog-description">
                            {" "}
                            {item?.summary
                              ? item?.summary
                                  .replace(regex, "")
                                  .replace(regexAmp, "&")
                                  ?.replace(regexnsbp, " ")
                                  .replace(regexGreater, "<")
                                  .replace(regexSmaller, ">")
                              : item?.summary
                                  .replace(regex, "")
                                  .replace(regexAmp, "&")
                                  ?.replace(regexnsbp, " ")
                                  .replace(regexGreater, "<")
                                  .replace(regexSmaller, ">")}
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#0B1D1299",
                              }}
                              className="text-capitalize"
                            >
                              {item?.authorName}
                            </div>
                            <div
                              onClick={() => {
                                BlogDetailsRoute(`/blogs/${item?.permaLink}`);
                              }}
                            >
                              <div className="readmore cursor flex ">
                                Read more
                                <BsArrowRight className="fs-4 ms-2" />
                                <div className="VectorImage">
                                  <img
                                    src="/assets/VectorImage.svg"
                                    alt="img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
                {filtered?.length > 3 ? (
                  <div className="col-12 text-center mt-3">
                    <div
                      className="categories-item"
                      onClick={() => handleSearchData(filtered?.[0]?.category)}
                    >
                      See More
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </Bg>
    </div>
  );
};

export default BlogDetails;
