import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/header";
import Footer from "../footer/footer";
import BgGridImage from "../../assets/images/bg_grid.png";
import { Helmet } from "react-helmet";
import cookies from "react-cookies";

const Layout = () => {
  const location = useLocation();
  const [consent, setConsent] = useState(false);

  // Check for existing consent on component mount
  useEffect(() => {
    const consentCookie = cookies.load("localConsent");
    if (consentCookie) {
      setConsent(consentCookie === "true");
    }
  }, []);

  // Function to handle user consent
  const handleConsent = () => {
    cookies.save("localConsent", "true", { path: "/", maxAge: 31536000 }); // 1 year
    setConsent(true);
  };

  // Function to dynamically load external scripts
  const loadScript = (src, async = true) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      document.head.appendChild(script);
    }
  };

  // Load scripts when consent is given
  useEffect(() => {
    if (consent) {
      // Google Tag Manager
      loadScript("https://www.googletagmanager.com/gtm.js?id=GTM-WDZD39JH");

      // Google Analytics
      loadScript("https://www.googletagmanager.com/gtag/js?id=GTM-WDZD39JH");
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "GTM-WDZD39JH");


      // LinkedIn Scripts
      window._linkedin_partner_id = "5998258";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
      loadScript("https://snap.licdn.com/li.lms-analytics/insight.min.js");

      // Add LinkedIn noscript
      const noscript = document.createElement("noscript");
      const img = document.createElement("img");
      img.height = "1";
      img.width = "1";
      img.style.display = "none";
      img.src = "https://px.ads.linkedin.com/collect/?pid=5998258&fmt=gif";
      noscript.appendChild(img);
      document.body.appendChild(noscript);
    }
  }, [consent]);

  return (
    <div>
      <Helmet>
        {/* Google Tag Manager Consent Defaults */}
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });
          `}
        </script>

        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet" />
      </Helmet>

      <Header />
      <div
        className="md:w-[100%] m-auto"
        style={{
          backgroundImage: location.pathname.includes("blogs") ? " " : `url(${BgGridImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <Outlet />
        <div>
          {location.pathname.includes("blogs") || location.pathname.includes("partner-with-us") ? (
            <hr className="mt-5" />
          ) : null}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
