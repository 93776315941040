const compareDate = [
  {
    name: "Dashboard",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Contacts",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Prospects",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Organisations",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Leads",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Deals",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Cold Reach",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Clients",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Import",
    basicPlan: true,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Analytics",
    basicPlan: false,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "KPI Dashboard",
    basicPlan: false,
    proPlan: true,
    customPlan: true,
  },
  {
    name: "Live chat",
    basicPlan: false,
    proPlan: false,
    customPlan: true,
  },
  {
    name: "Support",
    basicPlan: false,
    proPlan: false,
    customPlan: true,
  },
];

export {compareDate};