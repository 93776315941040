export const data = [
    {
        ques: "What roles can be assigned to users in LeadsynQ?",
        ans: "Admins in LeadsynQ can assign various roles such as managers, lead generators, and executives. Each role comes with specific permissions and access rights, allowing for a structured and hierarchical team management system. This ensures that users have the appropriate level of access based on their responsibilities."

    },
    {
        ques: "Can multiple admins be created in LeadsynQ?",
        ans: "Yes, LeadsynQ supports the creation of multiple admin users. Each newly created admin has full administrative access, allowing them to manage users, oversee tasks, and configure system settings, ensuring comprehensive control over the platform."

    },
    {
        ques: "What types of data can be imported into the Contacts module?",
        ans: "LeadsynQ allows lead generators and managers to import both requirement and non-requirement contact data. This can be done in bulk using CSV or XLXS file formats, making it easy to integrate large datasets into the system and ensure all relevant contacts are accounted for."

    },
    {
        ques: "How are tasks managed in the Cold Reach module?",
        ans: `In LeadsynQ’s Cold Reach module, tasks are organized into three categories: "To Do," "In Progress," and "Pending." These tasks automatically move between categories based on activity updates and due dates, allowing executives and managers to efficiently track the progress of their outreach efforts.`
    },
    {
        ques: "How can I assess team performance using LeadsynQ?",
        ans: `The Analytics module in LeadsynQ provides comprehensive tools to assess team performance. Admins can use charts and statistics to evaluate individual and team performance by role, as well as track progress over monthly, quarterly, and yearly intervals, allowing for data-driven decision-making and strategic planning.`
    }
]