import React from "react";
import FaqComponent from "../../components/faq-component";
import { data } from "../../constant/faq-data";

const FrequentlyAskedQuestion = () => {
  return (
    <div className="container  md:mt-[100px] mt-[50px]" id="faq">
      <div className="md:w-[70%] mx-auto">
        <p className="md:text-[40px] text-[30px] text-center font-bold">
          Frequently asked{" "}
          <span
            style={{
              backgroundImage:
                "linear-gradient(266.01deg, #55CBFB -21.73%, #1D4ED8 96.22%)",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            questions
          </span>
        </p>
        <p className="text-[16px]  text-[#888888] text-center">
          {
            "Have questions about our CRM solution? We've got you covered. Explore our frequently asked questions to find answers to common queries and gain a deeper understanding of how our platform can help streamline your business operations and drive growth."
          }
        </p>
      </div>
      <div>
        <FaqComponent data={data} />
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestion;
